/* eslint-disable arrow-body-style */
import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { FormItem, Input, Segmented, Select, TSegmentedArray, TSegmentedItem, Text, colors } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import { TVoteType } from "interfaces/IVote";
import { validation } from "modules/validation/utils/validation";
import { ReactComponent as ExcludeSvg } from "public/exclude.svg";
import { ReactComponent as VoteSvg } from "public/vote.svg";

import PollsEditValidationStore from "../../../../store/PollsEdit.validation.store";
import PollsEditStore from "../../../../store/PollsItem.store";
import PollsItemStore from "../../../../store/PollsItem.store";
import PollsEditContentCard from "../../../PollsEditContent.card";

import usePollTemplatesSelectItems from "./hooks/usePollTemplatesSelectItems";

interface IPollsDetailNameCard {
	editable?: boolean;
}

const segmentedItems: TSegmentedArray = [
	{
		title: "Опрос",
		icon: <ExcludeSvg />,
		key: "POLL",
	},
	{
		title: "Голосование",
		icon: <VoteSvg />,
		key: "VOTE",
	},
];

const PollsDetailNameCard: FC<IPollsDetailNameCard> = (props) => {
	const templatePollsSelectItems = usePollTemplatesSelectItems();
	const isTemplate = PollsItemStore.poll.isTemplate;
	const { type } = PollsEditStore.poll;

	const onChangePollName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const trimmedValue = value?.trim();

		PollsEditStore.changePoll({ key: "name", value });

		const isValid = validation.isNotEmpty(trimmedValue) && validation.isLessThen(trimmedValue, 255);

		if (!isValid) {
			PollsEditValidationStore.isValidPollName = false;
		} else {
			PollsEditValidationStore.validPollName();
		}
	};

	const onChangePollType = (segmentedItem: TSegmentedItem) => {
		PollsEditStore.changePoll({ key: "type", value: segmentedItem.key as TVoteType });
	};

	const pollTypeLabel = React.useMemo(() => {
		if (isTemplate) {
			return "Название шаблона";
		}
		switch (type) {
			case "VOTE":
				return "Название голосования";
			case "POLL":
				return "Название опроса";
			default:
				return "Некорректный тип";
		}
	}, [isTemplate, type]);

	const pollTypeMessage = React.useMemo(() => {
		if (isTemplate) {
			return "Название шаблона не может быть пустым или быть более 255 символов";
		}
		switch (type) {
			case "VOTE":
				return "Название голосования не может быть пустым или быть более 255 символов";
			case "POLL":
				return "Название опроса не может быть пустым или быть более 255 символов";
			default:
				return "Некорректный тип";
		}
	}, [isTemplate, type]);

	return (
		<>
			<PollsEditContentCard style={{ paddingTop: "24px", paddingBottom: "32px" }}>
				<>
					<FormItem
						label={pollTypeLabel}
						message={!PollsEditValidationStore.isValidPollName && pollTypeMessage}
						state={PollsEditValidationStore.isValidPollName ? "default" : "error"}>
						<CustomInput
							value={PollsEditStore.poll.name}
							onChange={onChangePollName}
							sizeInput={"large"}
							placeholder={pollTypeLabel}
							state={PollsEditValidationStore.isValidPollName ? "default" : "error"}
							disabled={!props.editable}
						/>
					</FormItem>

					{props.editable && (
						<>
							<Spacer px={10} />

							<Row>
								{isTemplate ? (
									<EmptyDiv />
								) : (
									<>
										<Text color={colors.textNeutralSecondary} type="table-cell-m-regular">
											Шаблон:{" "}
										</Text>

										<Spacer px={4} />

										<SelectWrapper>
											<Select
												placeholder="Без шаблона"
												onClickSelect={templatePollsSelectItems.onClickSelect}
												onClickSelectItem={templatePollsSelectItems.onChangeTemplate}
												items={templatePollsSelectItems.items}
												isOpened={templatePollsSelectItems.isOpenSelect}
												size="m"
												style={{ width: "300px" }}
												selectItemsListWidth="300px"
											/>
											<Segmented
												selectedKey={PollsEditStore.poll.type}
												items={segmentedItems}
												direction={"horizontal"}
												size="m"
												onClick={onChangePollType}
											/>
										</SelectWrapper>
									</>
								)}
							</Row>
						</>
					)}
				</>
			</PollsEditContentCard>
		</>
	);
};

export default observer(PollsDetailNameCard);

const CustomInput = styled(Input)`
	width: 100%;
`;

const Row = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const SelectWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;
	flex-grow: 0;
`;

const EmptyDiv = styled.div`
	flex: 1;
`;
